<script>
    
</script>

<style>
    .main-container {
        background-color: #36422b;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .brand {
        color: white;
        font-size: 18vw;
        
    }
</style>

<div class="main-container">
    <div class="brand">dulubi</div>
</div>